exports.components = {
  "component---src-pages-case-asfalt-js": () => import("./../../../src/pages/caseAsfalt.js" /* webpackChunkName: "component---src-pages-case-asfalt-js" */),
  "component---src-pages-case-gmg-js": () => import("./../../../src/pages/caseGmg.js" /* webpackChunkName: "component---src-pages-case-gmg-js" */),
  "component---src-pages-case-teunesen-js": () => import("./../../../src/pages/caseTeunesen.js" /* webpackChunkName: "component---src-pages-case-teunesen-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */)
}

